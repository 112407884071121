import React from 'react';
import '../styles/globals.css';
import { Helmet } from 'react-helmet';
import { Footer, Grid, HeroSection, Navigation, SectionHeading } from '../components';
import { GridItem } from '../types';

const transportationSolutions: GridItem[] = [
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Account-based ticketing',
        content: `Having built one of the most complex hybrid e-ticketing systems in CEE, we’ve seen the best and worst of both closed- and open-loop solutions. The solution that we’re currently offering relies on fast, backend-driven validation and inspection services, which greatly reduces the complexity and maintenance burden, for example,  of the business logic layer of edge periphery touchpoint networks. Monitoring, troubleshooting and updating tens of thousands of validators, especially in terms of product catalogue management and closed-loop security (i.e. SAM key updates and counter resets) isn’t any longer a necessary tradeoff to unreliable network connectivity. Our fast backend services, written in Golang, will respond to edge device request in milliseconds over 4G networks, with the necessary failover mechanisms in place, as well.`,
      },
      {
        title: '',
        content: `In terms of network administration and security of complex onprem or hybrid e-ticketing implementations, we know that even a fully virtualized solution, that uses an awesome opensource infrastructure stack can still be a challenge to monitor and operate, with tens of bare-metal servers in multiple DC locations that need to connect to thousands of touchpoints via APNs and dedicated optical lines. So, we rely entirely on cloud-native technologies to deliver standard e-ticketing features and services, such as user, customers and product catalogue management, validation and inspection metrics and services, and reporting and reconcilliation dashboards in a backend-first environment.`,
      },
      {
        title: '',
        content: `Our ABT solutions are event-driven by design, which means we use technologies like Apache Kafka, Kubernetes and PostgresSQL to deploy, connect and store data exchanged between separate microservices, each containing unique business logic. With this kind of architecture in place, our systems can scale and adapt to business needs, traffic loads and integration requirements in time-to-markets previously unseen. When transit authorities choose our solutions they ultimately gain the flexibility to open, develop or integrate digital sales channels of any kind, depending on their local business context and customer preferences. Because we are a data-driven ICT company, all our solutions have AI and ML capabilities embedded at their core.`,
      },
    ],
  },
  { type: 'image', image: 'solutions-grid-1.jpg', copyright: true },
  { type: 'image', image: 'solutions-grid-2.jpg' },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'QR mobile ticketing',
        content: `A core design principle of our systems is to depart from a centralized sale operations approach and move toward a decentralized, ecosystem-based, digital distribution system. To this end, we have developed an open API QR system, capable of issuing digital transit documents to interested third-party sellers. Our QR system is highly secure, with multiple cryptographic defense mechanisms, capable of issuing tickets in miliseconds and serving tens of thousand of events (validations, inspections, updates) every second.`,
      },
      {
        title: '',
        content: `Furthermore, our system has built-in fraud detection capabilities, which minimize the risk of QR duplication and sharing (either through screenshots or video streaming), and can work both in online mode (with request against our backend APIs) and in offline mode (with validator SDKs, which can decrypt and validate). This approach can increase the revenue of the public transit authority exponentially by creating a network of sellers, which are not only innovating independently to provide better services (better applications, more innovative products), but also use their own marketing means to acquire new customers. Furthermore, this approach reduces the operational costs of the transit agency, as the need for stationary sale points is greatly reduced in favour of digital distribution channels.`,
      },
    ],
  },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'AVL System',
        content: `Our Automatic Vehicle Location (AVL) system is capable of collecting GPS data from tens of thousand of vehicles in real time and provide back-end driven estimates about the expected schedule and route deviation. Furthermore, the system is capable of producing AI-driven insights about current traffic conditions of each vehicle, its occupancy status, its expected arrival time - not only for the next stop but for all remaining stops of the route, and to propagate this information to physical timetables, either in stops or in the vehicle itself. All real-time data is integrated into a GTFS real-time feed and is exposed as an open API to interested third-party MaaS applications.`,
      },
      {
        title: '',
        content: `The entire system can be controlled in real time from a dedicated dispatcher interface. When a change is applied by the dispatcher (for instance a change in a specific route), the information is instantly propagated to all passengers and to the entire IoT network. All data is kept as a historical log, which is then used by a dedicated course evaluation module, capable of finding out all difference between the assigned task and its actual execution by the operator. To this end, we have deployed this system in the context of Sofia’s public transit project, where it operates more than a thousand vehicles at any given time and processes terabytes of high-velocity data daily.`,
      },
    ],
  },
  { type: 'image', image: 'solutions-grid-3.jpg', copyright: true },
  { type: 'image', image: 'solutions-grid-4.jpg', copyright: true },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'EMV - Mobility Transit Transaction - Fare Rule Engines',
        content: `VISA MTT is one of the most popular open-loop transit schemes globally. Implementing this technology allows transit authorities to accept Visa, Mastercard and other payment cards on certified validators making it easy for anyone to get onboard a bus and tap to pay for a ticket. Because payments are processed at the end of the day, different business rules can be applied to offer customers best fare capping and aggregated payments.`,
      },
      {
        title: '',
        content: `Our Fare Rule Engine for STS (Sofia Ticketing System), for example, matches travel taps from the validator network with payments taps that we receive from the acquirer host backoffice system to calculate the best fare daily cap for each customer. We send batch aggregation requests to the payment processor at the daily cutoff at 01:00 AM and when we receive confirmation that the payment authorization requests were processed successfully, we issue fiscal receipts that are available to customers along with details of their journey history on the transit authority’s webportal. The rule engine can be configured to process weekly and monthly caps, or any other kind of business rule that may apply locally. Our experience also covers certification and integration of EMV L2 embedded kernels, and integration of L3 embedded applications for validators.`,
      },
    ],
  },
];

const wasteManagementSolutions: GridItem[] = [
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Live',
        content: `Our solution allows users to monitor the work of operators, inspectors and others, in real time. It integrates data from our smart vehicles and smart containers, and uses advanced algorithms to create intelligent alarms for various problems (stolen containers, route deviation, you name it). You will know where your vehicles are now and you will know the fill levels of your containers.`,
      },
      {
        title: 'Forecasting',
        content: `We provide you with a forecasting technique, which utilizes both volume indicators and weight sensors to estimate the effectiveness of future pickup events. Our solution knows when a container will be filled and whether it should be collected or not.`,
      },
      {
        title: 'Courses',
        content: `Customers get a tool for precise tracking of each waste collection truck, based on its GPS and CAN module data. All courses, all stops, all pickups. Everything. You can observe where the waste personnel have been at any given time, whether they have collected the container at a specific day, and what was the weight of this container.`,
      },
      {
        title: 'Weight',
        content: `Our solution can integrate various truck weight sensors into a smart weight monitoring system. This allows you to know what is the weight at the pickup time of each container. This functionality further helps you monitor more closely not only the waste collection operation but the citizens' behavior as well. The solution’s advanced analytics modules allow you to better understand the differences between various buildings, blocks, neighbourhoods and even cities in terms of their waste collection habits. You are not sure what is the ratio between recycled and mixed weight in one of your neighborhoods? You do not know whether your users throw more waste than they pay for? No problem, we can track that for you, understand the reasons behind it and help you find a solution.`,
      },
      {
        title: 'Alarming',
        content: `With our solution, you will not be only notified about problems with your operation, but will be able track this information historically. You will know the performance of your operation and will be able to see whether your decisions affect it. All alarms are customizable, can be sent via email, phone, or can be directly visualized on your dashboard.`,
      },
      {
        title: 'Collection efficiency',
        content: `One of the most important indicators of the success rate of your waste management operation is whether its users are satisfied. We provide you with tools to track the collection efficiency of each container, to understand whether it is collected too late or too early. Whether it is full or empty. You will be able to use this information to collect your containers just in time, by optimizing your routes in the best possible way and by changing the location and number of your containers. Our solution is fueled by advanced machine-learning algorithms, which take data from multiple truck sensors, such as cameras and weight scales.`,
      },
      {
        title: 'Pickups',
        content: `Using our solution, you will be able to track the collection of all containers. You will know whether a container, scheduled for collection, has been actually collected and when. The solution further gathers historic data about the pickup patterns in your operations area, which help you see their regularity and uncover critical gaps.`,
      },
    ],
  },
  { type: 'image', image: 'solutions-grid-5.jpg', copyright: true },
  { type: 'image', image: 'solutions-grid-6.jpg', copyright: true },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Container count and location',
        content: `Many historic waste management operation setups are based on "expert" opinion. Some follow the "one container per building entrance" rule, while others are purely random. Theoremus’ solution allows you to base the position and the number of your containers based on historic insights. You will be now able to set threshold volume levels for your containers and in return you will see their optimal position. This will help you minimize one of the biggest expenses for you operation — container purchase, maintenance and repair. Also, it will make the experience of your citizens way better, by minimizing their travel time to the container and container overfill rates.`,
      },
      {
        title: 'Route and schedule',
        content: `Our solution uses realistic representation of your operations area to make the best possible optimization for the container's collection. The solution knows which containers are expected to be filled at any given day, where are the containers which should not be collected during peak traffic hours or late at night. We can even help you understand where a truck can make a u-turn, based on historic movement data. This information, utilized through cutting-edge optimization techniques allows us to minimize the distance traveled by your vehicles, by keeping a close eye on other factors, which affect the citizens' well being, your operational cost, and your environmental impact. This will help you minimize both the working hours of personnel and your operational expenses, environmental footprint, and vehicle depreciation.`,
      },
      {
        title: 'Fleet and roster',
        content: `Bigger waste collection operations work with tons of personnel and machinery. Based on the schedules estimated by our optimizational techniques, our smart waste management solution can help you distribute them efficiently, by minimizing overwork and, if possible, the size of your personnel and fleet.`,
      },
    ],
  },
];

const CompanyPage = () => {
  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Theoremus</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
        <link rel='canonical' href='' />
      </Helmet>
      <Navigation />
      <HeroSection
        backgroundImage='solutions.jpg'
        copyright={true}
        heading='Solutions'
        subHeading={`Resolving today's urban challenges with the help of technology`}
      />
      <span id='smart-urban-mobility' />
      <SectionHeading
        backgroundColor='black'
        heading='Smart urban mobility'
        subHeading='Mobility business solutions, open API ecosystems and data analytics'
      />
      <Grid backgroundColor='black' gridItems={transportationSolutions} />
      <span id='digital-waste-management' />
      <SectionHeading
        backgroundColor='white'
        heading='Digital waste management'
        subHeading='Monitoring and optimization'
      />
      <Grid backgroundColor='white' gridItems={wasteManagementSolutions} />
      <Footer />
    </main>
  );
};

export default CompanyPage;
